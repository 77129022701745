


// ============================================================
// EVENT QUERIES
// ============================================================

import { useInfiniteQuery, useMutation } from "react-query";
import { QUERY_KEYS } from "../queryKeys";
import EVENT_SERVICE from "services/refactor/event";


export const useGetListEvent = ({ customerId = '', pageSize = 5, status }) => {
    return useInfiniteQuery({
        queryKey: [QUERY_KEYS.GET_LIST_EVENT, customerId, status],
        queryFn: async ({ pageParam = 1 }) => {
            const response = await EVENT_SERVICE.getListEvent({
                body: {
                    pageSize,
                    currentPage: pageParam,
                    filter: {
                        customer_id: customerId,
                        status
                    },
                }
            })
            return response
        },
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage && lastPage.data && lastPage.data.page_info) {
                const { current_page, total_page } = lastPage.data.page_info;
                if (current_page < total_page) {
                    return current_page + 1;
                }
            }
            return null;
        },
        onSuccess: (data) => {
            return data;
        },
        cacheTime: 0,
        staleTime: 0,
        keepPreviousData: false
    })
}


export const useAddRemindMe = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return EVENT_SERVICE.addRemindMe({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useUnAddRemindMe = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return EVENT_SERVICE.unAddRemindMe({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}