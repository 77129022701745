import {
    gql
} from '@apollo/client'
import { extractQueryInfo } from 'gql'

const GET_LIST_EVENT_QUERY = gql`
    query getEventList($pageSize: Int!, $currentPage: Int!, $filter: EventListFilterInput){
        getEventList(
            pageSize: $pageSize
            currentPage: $currentPage
            filter: $filter
            # filter: {
            #     customer_id: $customer_id,
            #     status: "Running"
            # } // example
            # sort: {
            #     field: "time_start",
            #     direction: $sort_direction
            # } // example
        ) {
            items { 
                event_id
                title
                status
                image
                image_url
                link_live_stream
                time_start
                time_end
                duration
                customer_id
                description
                created_at
                event_trigger {
                    event_trigger_id
                    sku
                    event_id
                    trigger_time
                    name
                    description
                }
                customer {
                    email
                    firstname
                    lastname
                    avatar
                }
                remindMe_id
                moderator {
                    moderator_id
                }
                add_calendar
                admin_id,
                url_key
            }
            page_info  {
                page_size
                current_page
                total_page
            }
        }
    }
`

const ADD_REMIND_ME_QUERY = gql`
    mutation addRemindMe($event_id: String!){
        addRemindMe (event_id : $event_id) {
            result
            # remind_id
        }
    }
`
const UN_ADD_REMIND_ME_QUERY = gql`
    mutation unRemindMe($remind_id: String!) {
        unRemindMe(remind_id: $remind_id) {
            result
        }
    }
`

const EVENT_QUERY = {
    GET_LIST_EVENT: extractQueryInfo(GET_LIST_EVENT_QUERY),
    ADD_REMIND_ME: extractQueryInfo(ADD_REMIND_ME_QUERY),
    UN_ADD_REMIND_ME: extractQueryInfo(UN_ADD_REMIND_ME_QUERY),
}

export default EVENT_QUERY