import React, { Suspense, lazy } from 'react';
import { initReactI18next } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from 'react-query';
import { routerLinks } from 'utils';
import { keyToken, linkApi, keyUser, keyExpired, keyAppVersion } from 'variable';
import { Provider } from 'react-redux';
import { store } from './store/index';
import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import AuthProvider from 'globalContext';
import Router from 'routes';
import reportWebVitals from './reportWebVitals';
import withClearCache from './withClearCache';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import showModalRequestUpdateAppVersion from 'utils/showModalRequestUpdateAppVersion';
import Message from 'components/Message';
import ModalLowNetwork from 'components/ModalLowNetwork';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.less';
import ApplicationProvider from 'components/ApplicationContext';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ClearCacheComponent = withClearCache(Router);

axios.defaults.baseURL = linkApi;
axios.defaults.headers.common.Authorization = 'Bearer ' + JSON.parse(localStorage.getItem(keyToken));
axios.defaults.headers.common['app-version'] = localStorage.getItem(keyAppVersion) || '1.0';
axios.defaults.timeout = 120000;
axios.interceptors.response.use(
    (response) => {
        if (response?.status === 500 || response?.status === 503) {
            window.location.pathname = routerLinks('Maintain');
            return false;
        }
        if (response?.data?.errors) {
            if (
                response?.data?.errors?.[0]?.message?.indexOf('The request is allowed for logged in customer') > -1 ||
                response?.data?.errors?.[0]?.message?.indexOf("The current customer isn't authorized") > -1 ||
                response?.data?.errors?.[0]?.message?.indexOf('The current user cannot perform operations on cart') > -1
            ) {
                if (!localStorage.getItem(keyToken)) return false;
                localStorage.removeItem(keyUser);
                localStorage.removeItem(keyToken);
                localStorage.setItem(keyExpired, '1');

                // setTimeout(() => {
                //     window.location.pathname = routerLinks('Account');
                // }, 500);

                return false;
            }

            if (response?.data?.errors?.[0]?.message?.indexOf('Please update your app to latest version') > -1) {
                showModalRequestUpdateAppVersion();
            }
        }
        return response;
    },
    async (error) => {
        const modalLowNetwork = document.getElementById('low-network');
        if (error?.code === 'ECONNABORTED' && modalLowNetwork) {
            modalLowNetwork.classList.remove('hidden');
        }
        if (error?.code === 'ERR_NETWORK' && modalLowNetwork && !navigator.onLine) {
            modalLowNetwork.classList.remove('hidden');
        }
        if (
            (!!error?.response && !!error?.response?.data?.errors && error?.response?.data?.errors === 401) ||
            error?.response?.status === 401
        ) {
            window.location.pathname = routerLinks('Account');
        }
        if (error?.response?.data?.message) {
            Message.error({ text: error?.response?.data?.message });
        }
        return false;
    }
);
i18n.use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        fallbackLng: 'en',
        whitelist: ['en', 'vi'],
        interpolation: {
            escapeValue: false,
        },
    });
const Styling = lazy(() => import('styling'));

const handleScale = () => {
    const minScreenWidth = 375;
    const breakPointMobileScreen = 400;
    const breakPointTableScreen = 768;
    // const breakPointDesktopScreen = 1025;
    const currentScreenWidth = window.innerWidth;

    const scale = currentScreenWidth / minScreenWidth;
    const content = 'width=' + minScreenWidth + ',initial-scale= ' + scale + ',maximum-scale=1,user-scalable=no';
    // const root = document.querySelector(":root");
    const viewportMetaTag = document.querySelector('meta[name="viewport"]');

    // For mobile smaller
    if (currentScreenWidth <= minScreenWidth) {
        viewportMetaTag.setAttribute('content', content);
        // root.style.fontSize = '1.125rem';
        window.isZoomed = true;
        // yeu cua cau anh Nghiz => design moi roi -> nen bo
    }
    // For mobile
    if (currentScreenWidth >= breakPointMobileScreen && currentScreenWidth < breakPointTableScreen) {
        viewportMetaTag.setAttribute('content', content);
        // root.style.fontSize = '18.24px';
    }
    // For table
    if (currentScreenWidth >= breakPointTableScreen) {
        viewportMetaTag.setAttribute('content', content);
        // root.style.fontSize = '16px';
    }
    if (document.getElementById('xblock') && document.getElementById('xblock').innerHTML) {
        document.getElementById('xblock').innerHTML += ' Size: ' + document.getElementById('xblock')?.clientWidth;
    }
};

document.addEventListener('DOMContentLoaded', () => {
    // handleScale();
});

window.addEventListener('resize', (e) => {
    //  handleScale();
});

function App() {
    const queryClient = new QueryClient();

    usePlacesService({
        apiKey: `${process.env.REACT_APP_GOOGLE_KEY}&loading=async`,
        options: {
            componentRestrictions: { country: ['us'] },
        },
    });

    return (
        <Suspense
            fallback={
                <div>
                    <div className="w-screen h-screen" />
                </div>
            }
        >
            {/* <ModalLowNetwork /> */}
            <Styling>
                <GoogleOAuthProvider clientId="254865787662-kk7obf2vesni7k1v87nrsurs2lsfh1la.apps.googleusercontent.com">
                    <QueryClientProvider client={queryClient}>
                        <ApplicationProvider>
                            <AuthProvider>
                                <Provider store={store}>
                                    <ClearCacheComponent />
                                </Provider>
                            </AuthProvider>
                        </ApplicationProvider>
                    </QueryClientProvider>
                </GoogleOAuthProvider>
            </Styling>

        </Suspense>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

serviceWorkerRegistration.register();

reportWebVitals();
