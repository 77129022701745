const { default: EVENT_QUERY } = require("gql/event");
const { handleGraphQLMutation } = require("services");

const EVENT_SERVICE = {
    getListEvent: ({ body }) => handleGraphQLMutation({
        schema: EVENT_QUERY.GET_LIST_EVENT,
        body
    }),
    addRemindMe: ({ body }) => handleGraphQLMutation({
        schema: EVENT_QUERY.ADD_REMIND_ME,
        body
    }),
    unAddRemindMe: ({ body }) => handleGraphQLMutation({
        schema: EVENT_QUERY.UN_ADD_REMIND_ME,
        body
    }),
}

export default EVENT_SERVICE